<template>
	<v-dialog dark v-model="data.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="full-dialog">
		<template v-slot:activator="{ on, attrs }">
			<v-btn outlined rounded v-bind="attrs" v-on="on" :class="data.class">
				{{ data.btnText }}
			</v-btn>
		</template>
		<v-layout justify-center style="max-width:1200px; margin:0 auto; height:100vh;" wrap align-center class="px-2 pb-2">
			<v-flex xs12>
				<div>
					<v-layout align-center class="my-2">
						<h2 class="white--text">
							Work List
						</h2>
						<v-spacer></v-spacer>
						<v-btn icon dark @click="data.dialog = false" color="#5680e9ff">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-layout>
					<div class="white--text">
						<div>
							Start Work:
							<strong class="PointColor1--text">
								{{ data.startdate }}
							</strong>
						</div>
						<div>
							Work Day:
							<strong class="PointColor1--text">
								{{ totalDay }}
							</strong>
							(Days)
						</div>
						<div>
							Work Time:
							<strong class="PointColor1--text">
								{{ Math.round(totalMinute / 60) }}
							</strong>
							(Hours)
						</div>
						<div>
							Work holiayDay:
							<strong class="PointColor1--text">
								{{ holiayDay }}
							</strong>
							(Days)
						</div>
						<div>
							Work holiayDay Time:
							<strong class="PointColor1--text">
								{{ Math.round(holiayDayTime / 60) }}
							</strong>
							(Hours)
						</div>
					</div>
					<v-layout align-center class="my-2">
						<DatepickerRange :picker="AttendancelistStartpicker" @input="AttendancelistDate"></DatepickerRange>
						<span class="mx-3 white--text"> ~ </span>
						<DatepickerRange
							:picker="AttendancelistEndpicker"
							:allowed_dates="AttendancelistCouselor"
							@input="AttendancelistDate"
						></DatepickerRange>
						<v-btn small class="excel_btn mr-2" @click="clickExport()"><img src="@/assets/excel-img2.png" />엑셀파일 다운로드</v-btn>
						<v-spacer></v-spacer>
						<textField v-model="search" :textField="SearchTextField" />
					</v-layout>
					<datatable :datatable="AttendancelistDatatable" :search="search" />
				</div>
			</v-flex>
		</v-layout>
		<download-excel
			class="btn btn-default"
			id="atExport"
			:data="exportDatas"
			style="display:none"
			:fields="json_fields"
			type="text/csv;charset=utf8"
			worksheet="My Worksheet"
			name="프로텍트_출퇴근현황"
		>
		</download-excel>
	</v-dialog>
</template>
<script>
import datatable from './datatable.vue'
import textField from './textField'
import DatepickerRange from './DatepickerRange'
export default {
	components: {
		datatable,
		textField,
		DatepickerRange,
	},
	data() {
		return {
			exportDatas: [],
			json_fields: {
				이름: 'Name',
				업무시작일시: 'work',
				업무종료일시: 'Leave',
			},
			AttendancelistStartpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'start',
				height: 48,
				width: 200,
			},
			AttendancelistEndpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'end',
				height: 48,
				width: 200,
			},
			search: '',
			SearchTextField: {
				dark: true,
				width: '200',
				placeholder: 'Search',
				['prepend-inner-icon']: 'mdi-magnify',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			AttendancelistDatatable: {
				headers: [
					{ text: 'No', value: 'No', sortable: false },
					{ text: 'Name', value: 'Name' },
					{ text: 'Start Work', value: 'work' },
					{ text: 'Leave', value: 'Leave' },
					{ text: 'WorkTime', value: 'WorkTime' },
					{ text: 'Notes', value: 'notes' },
				],
				items: [],
				hidedefaultfooter: false,
				hidedefaultheader: false,
				singleExpand: true,
			},
			totalMinute: 0,
			totalDay: 0,
			holiayDay: 0,
			holiayDayTime: 0,
		}
	},
	props: {
		data: {
			type: Object,
		},
	},
	watch: {
		data: {
			deep: true,
			handler() {
				this.Attendancelist()
			},
		},
	},
	methods: {
		clickExport() {
			document.getElementById('atExport').click()
		},
		AttendancelistDate() {
			this.AttendancelistStartpicker.menu = false
			this.AttendancelistEndpicker.menu = false
			this.Attendancelist(this.AttendancelistStartpicker.date, this.AttendancelistEndpicker.date)
		},
		AttendancelistCouselor(val) {
			let start = this.$moment(this.AttendancelistStartpicker.date).format('YYMMDD')

			let startDate = new Date(this.AttendancelistStartpicker.date)
			let end = new Date(startDate.setDate(startDate.getDate() + 3650))
			let endDate = this.$moment(end).format('YYMMDD')

			val = this.$moment(val).format('YYMMDD') < endDate && this.$moment(val).format('YYMMDD') > start
			return val
		},
		Attendancelist(workday_gte, workday_lte) {
			if (this.data.interID) {
				let data
				if (workday_lte && workday_gte) {
					data = {
						workday_gte: workday_gte,
						workday_lte: workday_lte,
						interID: this.data.interID,
					}
				} else {
					data = {
						workday_gte: this.$moment(new Date()).format('YYYY-') + '01-01',
						interID: this.data.interID,
					}
				}
				this.$store
					.dispatch('Attendancelist', data)
					.then(res => {
						this.AttendancelistDatatable.items = res.attendances
						const settingAttDatas = JSON.parse(JSON.stringify(res.attendances))
						for (let index = 0; index < settingAttDatas.length; index++) {
							const el = settingAttDatas[index]
							el.work = this.$moment(el.work).format('YYYY-MM-DD HH:mm')
							el.Leave = el.Leave ? this.$moment(el.Leave).format('YYYY-MM-DD HH:mm') : null
							this.exportDatas.push(el)
						}

						this.totalMinute = 0
						this.totalDay = 0
						this.holiayDay = 0
						this.holiayDayTime = 0
						let monthWorks = [
							{ [1]: 0 },
							{ [2]: 0 },
							{ [3]: 0 },
							{ [4]: 0 },
							{ [5]: 0 },
							{ [6]: 0 },
							{ [7]: 0 },
							{ [8]: 0 },
							{ [9]: 0 },
							{ [10]: 0 },
							{ [11]: 0 },
							{ [12]: 0 },
						]
						res.attendances.forEach(attendance => {
							if (this.$moment(attendance.work).day() !== 0 && this.$moment(attendance.work).day() !== 6) {
								let t2
								if (attendance.Leave) {
									t2 = this.$moment(attendance.Leave)
								} else {
									t2 = this.$moment(attendance.work).add(6, 'hours')
								}
								const t1 = this.$moment(attendance.work)
								this.totalMinute += t2.diff(t1, 'minutes')
								this.totalDay += 1
								monthWorks[Number(this.$moment(attendance.work).format('MM')) - 1][Number(this.$moment(attendance.work).format('MM'))] +=
									t2.diff(t1, 'minutes') / 60
							} else {
								this.holiayDay += 1
								let t3 = 0
								if (attendance.Leave) {
									t3 = this.$moment(attendance.Leave)
								} else {
									t3 = this.$moment(attendance.work).add(6, 'hours')
								}
								const t4 = this.$moment(attendance.work)
								this.holiayDayTime += t3.diff(t4, 'minutes')
							}
						})
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
	},
	created() {
		this.AttendancelistStartpicker.date = this.$moment(new Date()).format('YYYY-') + '01-01'
		this.AttendancelistEndpicker.date = this.$moment(new Date()).format('YYYY-MM-DD')
	},
}
</script>
